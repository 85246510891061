import axios from 'axios'
import {
  baseURL,
  contentType,
  debounce,
  requestTimeout,
} from '@/config'
import store from '@/store'
import qs from 'qs'
import { message } from 'ant-design-vue'

/**
 * @description axios初始化
 */
const instance = axios.create({
  baseURL,
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType,
  },
})

/**
 * @description axios请求拦截器
 */
instance.interceptors.request.use(
  (config) => {
    if (store.getters['user/accessToken'])
      config.headers['Authorization'] = 'Bearer ' + store.getters['user/accessToken']
    if (
      config.data &&
      config.headers['Content-Type'] ===
      'application/x-www-form-urlencoded;charset=UTF-8'
    )
      config.data = qs.stringify(config.data)
    if (debounce.some((item) => config.url.includes(item))) {
      //这里写加载动画
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * @description axios响应拦截器
 */
instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    if (error.response.status == 400) {
      if (error.response.data.error.message == "The provided current password is invalid") {
        message.warning('提供的当前密码无效!');
      }
    }
    if (error.response.status == 403) {
      message.warning('当前页面已失效，请重新登录！')
      await store.dispatch('user/logout')
      window.location.href = '/';
    }

    // 在这里对请求错误进行处理
    return Promise.reject(error);
  }
)

export default instance
