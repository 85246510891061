<template>
  <div class="vab-avatar">
    <a-dropdown>
      <span class="ant-dropdown-link">
        <a-avatar :src="require('../../assets/img/user_avatar.png')" />
        {{ 'admin，' + timeFix() }}
        <DownOutlined />
      </span>
      <template v-slot:overlay>
        <a-menu>
          <a-menu-item @click="changePwd">修改密码</a-menu-item>
          <a-menu-item @click="logout">退出登录</a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    <a-modal v-model:visible="visible" title="修改密码" @ok="handleSubmit" @cancel="resetForm">
      <a-form @submit.prevent :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="原密码：" v-bind="validateInfos.currentPassword" name="currentPassword">
          <a-input-password v-model:value="modelRef.currentPassword" placeholder="请输入" />
        </a-form-item>
        <a-form-item label="新密码：" v-bind="validateInfos.password" name="password">
          <a-input-password v-model:value="modelRef.password" placeholder="请输入" />
        </a-form-item>
        <a-form-item label="重复密码：" v-bind="validateInfos.passwordConfirmation" name="passwordConfirmation">
          <a-input-password v-model:value="modelRef.passwordConfirmation" placeholder="请输入" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
  import { recordRoute } from '@/config'
  import { DownOutlined } from '@ant-design/icons-vue'

  import { useStore } from 'vuex'
  import { computed, ref, reactive } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { Form, Modal } from 'ant-design-vue';
  import { changePassword } from '@/api/user'
  import { message } from 'ant-design-vue'

  export default {
    name: 'VabAvatar',
    components: { DownOutlined },
    setup() {
      const visible = ref(false);
      const store = useStore()
      const router = useRouter()
      const route = useRoute()

      const pwdForm = Form.useForm;

      const logout = async () => {
        Modal.confirm({
          title: '确认要退出登录吗？',
          okText: '确认',
          cancelText: '取消',
          async onOk() {
            Modal.destroyAll();
            await store.dispatch('user/logout')
            if (recordRoute) {
              const fullPath = route.fullPath
              router.push(`/login?redirect=${fullPath}`)
            } else {
              router.push('/login')
            }
          },
          onCancel() { },
        });

      }

      function timeFix() {
        const time = new Date()
        const hour = time.getHours()
        return hour < 9 ? '早上好' : (hour <= 11 ? '上午好' : (hour <= 13 ? '中午好' : (hour < 20 ? '下午好' : '晚上好')))
      }

      const changePwd = () => {
        visible.value = true;
      }

      const modelRef = reactive({
        password: '',
        passwordConfirmation: '',
        currentPassword: ''
      });

      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== modelRef.password) {
          callback(new Error("两次密码输入请保持一致"))
        } else {
          callback()
        }
      }

      const { resetFields, validate, validateInfos } = pwdForm(
        modelRef,
        reactive({
          password: [
            {
              pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$%^&*!_\-+=()])[a-zA-Z\d@#$%^&*!_\-+=()]+$/, message: '请输入字母、数字或特殊字符@#$%^&*_-+=()', trigger: 'change'
            },
            {
              pattern: /^.{6,15}$/, message: '请输入6-15位密码', trigger: 'change'
            },
            {
              required: true,
              message: '请输入密码',
              trigger: 'change',
            },
          ],
          passwordConfirmation: [
            {
              required: true,
              validator: validatePass2,
              trigger: ['blur', 'change'],
            },
          ],
          currentPassword: [
            {
              required: true, message: '请输入原密码', trigger: ['blur', 'change'],
            },
          ],
        }),
      );

      const handleSubmit = () => {
        validate()
          .then(async res => {
            if (res.currentPassword == res.password) {
              message.warning("新密码不可与旧密码一致！")
              return;
            }
            changePassword(res).then((res) => {
              message.success("密码修改成功，请重新登录！")
              setTimeout(async () => {
                await store.dispatch('user/login', modelRef)
              }, 1500)
            })
          })
          .catch(err => {
            console.log('err', err);
          });
      };

      const resetForm = () => {
        resetFields();
      };

      return {
        password: computed(() => store.getters['user/password']),
        logout,
        changePwd,
        visible,
        modelRef,
        validateInfos,
        handleSubmit,
        timeFix,
        resetForm
      }
    },
  }
</script>
<style lang="less" scoped>
  .ant-avatar {
    margin-right: 5px;
  }

  .vab-avatar {
    .ant-dropdown-link {
      display: block;
      min-height: 64px;
      cursor: pointer;
    }
  }

  .ant-form-item-label {
    width: 115px;
  }

  .ant-input-password {
    width: 295px;
  }

  :deep .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    font-size: 10px !important;
    transform: translateY(-3px) !important;
  }

  .ant-form-item {
    margin-bottom: 20px !important;

    &:nth-last-child(1) {
      margin-bottom: 0 !important;
    }
  }
</style>