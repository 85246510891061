<template>
  <a-menu-item :key="routeChildren.path" @click.capture="handleLink">
    <img :src="require('@/assets/img/'+routeChildren.meta.icon+'.png')" alt="">
    <span>{{ routeChildren.meta.title }}</span>
  </a-menu-item>
</template>

<script>
  import { isExternal } from '@/utils/validate'
  // import VabIcon from '@/layout/vab-icon'
  export default {
    name: 'MenuItem',
    components: {},
    props: {
      item: {
        type: Object,
        default() {
          return null
        },
      },
      routeChildren: {
        type: Object,
        default: () => null,
      },
    },
    methods: {
      handleLink() {
        const routePath = this.routeChildren.fullPath
        const target = this.routeChildren.meta.target
        if (target === '_blank') {
          if (isExternal(routePath)) window.open(routePath)
          else if (this.$route.path !== routePath) window.open(routePath.href)
        } else {
          if (isExternal(routePath)) window.location.href = routePath
          else if (this.$route.path !== routePath) this.$router.push(routePath)
        }
      },
    },
  }
</script>
<style scoped>
  img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
</style>