import request from '@/utils/request'

export async function login(data) {
  return request({
    url: '/api/auth/local',
    method: 'post',
    data,
  })
}

export function getUserInfo(accessToken) {
  return request({
    url: '/api/users/me',
    method: 'get',
  })
}

// 修改密码
export async function changePassword(data) {
  return request({
    url: '/api/auth/change-password',
    method: 'post',
    data,
  })
}